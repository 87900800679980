import React from 'react'
import '../styles/contact.css'
import axios from 'axios';
import {URL} from '../config'

export default function Contact() {


  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailInput = e.target.elements.email
    const nameInput = e.target.elements.name
    const messageInput = e.target.elements.message

    try {
      const res = await axios.post(`${URL}/emails/send_email`, {
        name: nameInput.value,
        email: emailInput.value,
        message: messageInput.value,
        subject: 'Here is the message...',
      });
      if (res.data.ok) {
        nameInput.value = '';
        emailInput.value = '';
        messageInput.value = '';
        alert("your message has been sent")
      

      }
      

    } catch (err ) {
      console.log(err);
    }

  };







  return (
    <div>

      <div className='contact-title'>
        <h1> &#128075; Hello, </h1>
        <h1>how can we help?</h1>
      </div>

<div className="contact-wrapper">

<form onSubmit={handleSubmit} className="contact-container">

  <h3>Get in touch!</h3>

  <input className="contact-email-input" 
  type="email" 
  name="email" 
  required
  placeholder='E-Mail' />

  <input className="contact-email-input" 
  type="text" 
  name="name"
  required 
  placeholder='Your Name' />


  <textarea className="contact-input" 
  name='message' 
   placeholder='Your Message...' />

  <button>Send Message</button>
 
</form>
</div>
      


    </div>
  )
}

