import React from 'react'
import Helmet from 'react-helmet'

export default function Home(props) {

  return (
    <div>
      <Helmet>
        <title>Home</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of home page with React.js" />
        <meta name="keywords" content="accounts, authentication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <h1 className='home-text'>Hey there!</h1>

      <div className='home-photo-text'>
        <img className='home-img' src="https://images.unsplash.com/photo-1556910638-6cdac31d44dc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80" alt="" />
        <div>
          <p>Plateo is a store for ceramics, kitchenware and other household items &#128512; .  </p>
          <p>
            We hope that you will find everything you are looking for in our collection, we have taken the finest hand-crafted kitchenware from across Europe and merged it into one convenient outlet store.
          </p>
          <p>  This Full-Stack e-commerce webstore was made using React, NodeJS and MongoDB including features from Nodemailer, Stripe, React Router, AdminJS and JWT Authentication. </p>
        </div>
      </div>



    </div>

  )
}
