import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Helmet from 'react-helmet'
import {URL} from '../config'

const PaymentSuccess = (props) => {

  const [order, setOrder] = useState([])

  useEffect(() => {
    const getSessionData = async () => {

      // 11. Now when payment was successful we need to get back to Stripe to know what was paid for and who is the customer
      try {
        // 12. we get the session id from the localStorage
        const sessionId = JSON.parse(localStorage.getItem("sessionId"));
        // 13. And send request to checkout_session controller to get info from Stripe by session ID
        const response = await axios.get(
          `${URL}/payment/checkout-session?sessionId=${sessionId}`
        );
        localStorage.removeItem("sessionId");

        console.log("== response ==>", response);

        const sessionData = response.data.session.line_items.data;
        setOrder([...sessionData]);
        debugger
      } catch (error) {

      }
    };
    getSessionData();
  }, []);





  return (
    <div>

      <Helmet>
        <title>Payment Success!</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of payment success page with React.js and JWT authentication" />
        <meta name="keywords" content="accounts, authetication, stripe payments, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>


      <div><h2>Thank you for your order!!</h2></div>

      <h3>Order Summary:</h3>
      <div className="order-summary">
        {order.map((item) => (
          <div>
            <h3>{item.description}</h3>
            <p>{item.quantity} units</p>
            <p>{item.amount_total / 100}€ each</p>

          </div>
        ))}
        <p>Your order will arrive at your residence within 10 business days</p>
      </div>

    </div>
  );
};

export default PaymentSuccess;

