import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import '../styles/single-catsproducts-styles.css'
import Helmet from 'react-helmet'
import { URL } from '../config'

export default function SingleCategory(props) {

  const { onAdd } = props;


  const [singleCategory, setCategory] = useState([]);
  const [products, setProducts] = useState([]);

  const params = useParams();




  useEffect(() => {
    const getCat = async () => {
      axios.get(`${URL}/categories/${params.category}`)
        .then((res) => {
          setCategory(res.data)
          console.log(singleCategory)


        })
        .catch((err) => {
          console.log(err)

        })
    }
    getCat()
  }, [])

  useEffect(() => {
    const getProds = async () => {
      axios.get(`${URL}/products/`)
        .then((res) => {
          setProducts(res.data)
          console.log(products)
        })
        .catch((err) => {
          console.log(err)

        })
    }
    getProds()
  }, [])

  let renderProducts = () => (
    products.map((prod, idx) => {
      if (prod.category == params.category)
        return <div className='cat-card' key={prod._id}>
          <div className='image-wrapper'><img src={prod.product.img}></img></div>
          <div className='info-wrapper'>
            <div><h3><Link to={`/products/${prod._id}`} className='cat-title'>{prod.product.name}</Link></h3></div>
            <div className='cat-info'>
              <p className='price'>€{prod.product.price}</p>
              <div className='addcart-price'>
                <button onClick={() => onAdd(prod)}>Add to Cart</button>
              </div>
            </div>
          </div>
        </div>
    }



    ))


  return (
    <div>
      <Helmet>
        <title>Category</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of Category page with React.js and JWT authentication" />
        <meta name="keywords" content="accounts, authetication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>


      <div style={{ textAlign: 'left', padding: '1em', border: '1px solid black', width: 'fit-content' }}><p><Link to="/categories">Go back?</Link></p></div>
      <h1>{singleCategory.category}</h1>
      <div className='singlecats'>{renderProducts()}</div>



    </div>

  )
}