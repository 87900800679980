import React from "react";
import Helmet from 'react-helmet'

const PaymentError = (props) => {

  return (
    <div>
      <Helmet>
        <title>Payment Error</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of payment error page with React.js and   JWT authentication" />
        <meta name="keywords" content="accounts, authetication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <div style={{ border: "2px solid  #FF395B" }} >
        <div>

        </div>
        <div style={{ color: "#FF395B" }}>
          Payment Error! Please try again.
        </div>
      </div>
    </div>
  );
};

export default PaymentError;

