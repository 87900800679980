import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Helmet from 'react-helmet'
import {URL} from '../config'


export default function Products(props) {

  const { product, onAdd, cartItems } = props
  console.log(cartItems)

  const [currentView, setCurrentView] = useState("Category")
  const [products, setProducts] = useState([])

  let handleLowHigh = () => {
    setCurrentView('Price (low to high)')
    const lowhigh = products.sort((a, b) => a.product.price - b.product.price)
    setProducts([...lowhigh])
  }

  let handleHighLow = () => {
    setCurrentView('Price (high to low)')
    const highlow = products.sort((a, b) => b.product.price - a.product.price)
    setProducts([...highlow])
  }

  let handleColour = () => {
    setCurrentView('Colour')
    const colour = products.sort((a, b) => a.product.color.localeCompare(b.product.color))
    setProducts([...colour])
  }

  let handleCategory = () => {
    setCurrentView('Category')
    const category = products.sort((a, b) => a.category.localeCompare(b.category))
    setProducts([...category])
  }


  let getProducts = () => {
    axios.get(`${URL}/products/`)
      .then((res) => {
        setProducts(res.data)

      })
      .catch((err) => {
        console.log(err)

      })
  }


  useEffect(() => {
    getProducts()
    console.log(products)
  }, [])



  let renderProducts = () => (

    products.map((prod, idx) =>

      <div className='product-card' key={prod._id}>
        <div className='image-wrapper'><img src={prod.product.img}></img></div>
        <div className='info-wrapper'>
          <div>
            <h3>
              <Link to={`/products/${prod._id}`} className='product-title'>
                {prod.product.name}
              </Link>
            </h3>
          </div>
          <div className='product-info'>
            <p className='price'>€{prod.product.price}</p>
            <div className='addcart-price'>
              <button onClick={() => onAdd(prod)}>Add to Cart</button>
              {cartItems.some(ele => ele._id == prod._id) ? <p>Added to Cart!</p> : null}
            </div>
          </div>
        </div>
      </div>

    ))



  return (
    <div>

      <Helmet>
        <title>Products</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of products page with React.js and JWT authentication" />
        <meta name="keywords" content="accounts, authetication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <div className="filterList">
        <p className="dropbtn">Sort by:</p>
        <div className='sort-options'>
          <a onClick={handleCategory}>Category</a>
          <a onClick={handleLowHigh}>Price (low to high)</a>
          <a onClick={handleHighLow}>Price (high to low)</a>
          <a onClick={handleColour}> Colour</a>
        </div>
      </div>
      <button>{currentView}</button>

      <div className="products">{renderProducts()}
      </div>



    </div>
  )
}
