import React from 'react'
import '../styles/burger-menu.css'
import { NavLink } from 'react-router-dom';

export default function BurgerMenu(props) {
 const {countCartItems} = props;



  return (
    <nav>
      <div className="burger-navbar" onClick={()=>console.log('close')}>
     
        <div className="container nav-container">
        <input className="checkbox" type="checkbox" name="" id="" />


            <div className="hamburger-lines">
            
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
             
            </div>  
            


            
          <div className="menu-items">
            <li><a href="/">Home</a></li>
            <li><a href="/products">Shop All Products</a></li>
            <li><a href="/categories">Shop All Categories</a></li>
            <li><a href="/contact">Contact</a></li>
            <NavLink to={'/cart'}><li>Cart ({countCartItems})</li></NavLink>
            <li><a href="/account">Account</a></li>
            


          </div>
        </div>
      </div>
    </nav>
  )
}
