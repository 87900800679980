import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';
import { URL } from '../config'
import Helmet from 'react-helmet'



export default function Cart(props) {

    const { cartItems, onAdd, onRemove, setCartItems, } = props;

    const [clearMsg, setClearMsg] = useState("")


    const cartTotal = Number(cartItems.reduce((a, c) => a + c.product.price * c.quantity, 0).toFixed(2));
    let shippingCost = cartTotal < 80 ? 25 : 0;
    const totalToPay = cartTotal == 0.00 ? null : (Math.floor((cartTotal + shippingCost) * 100) / 100).toFixed(2);


    const handleClear = () => {
        setClearMsg('All items removed from cart!');
        setCartItems([]);

    }

// useEffect(()=>{console.log(totalToPay,shippingCost,cartTotal,typeof cartTotal.toFixed(2))},[totalToPay])

    useEffect(() => {
        const clear = setTimeout(() => {
            setClearMsg("");
        }, 4000);

    }, [clearMsg])


    ////////// CHECK OUT ///////////////////

    const navigate = useNavigate();
    const stripe = useStripe();
    console.log(cartItems);

    const products = cartItems.map((ele) => {
        return {
            name: ele.product.name,
            images: [ele.product.img],
            quantity: ele.quantity,
            amount: ele.product.price,
        }

    });

    console.log(products)




    // 1. When we click PAY button this function triggers first 
    const createCheckoutSession = async () => {

        try {
            // 2. Sending request to the create_checkout_session controller and passing products to be paid for
            const response = await axios.post(`${URL}/payment/create-checkout-session`, { products });
            debugger
            return response.data.ok
                // we save session id in localStorage to get it later
                ? (localStorage.setItem('sessionId', JSON.stringify(response.data.sessionId)),
                    // 9. If server returned ok after making a session we run redirect() and pass id of the session to the actual checkout / payment form
                    redirect(response.data.sessionId))
                : navigate('/payment/error');
        } catch (error) {
            navigate('/payment/error');
        }
    };

    const redirect = (sessionId) => {
        // 10. This redirects to checkout.stripe.com and if charge/payment was successful send user to success url defined in create_checkout_session in the controller (which in our case renders payment_success.js)
        stripe
            .redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: sessionId
            })
            .then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
    };




    return (
        <div>
            <Helmet>
                <title>Cart</title>
                <meta charSet="utf-8" />
                <meta name="description" content="Example implementation of shopping cart page with React.js and Stripe payments" />
                <meta name="keywords" content="shopping cart, authetication, stripe payment" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>

            <h1 style={{ color: "rgba(85, 81, 102, 1)" }}>Cart</h1>
            <div><h3 className='clear-msg'>{clearMsg}</h3></div>

            <div className='cart-container'>
                {cartItems.length === 0 && <div style={{ color: "rgba(85, 81, 102, 1", marginTop: "2em" }}>
                    Your Cart is Empty :(
                </div>}
                {cartItems.map((item) => (
                    <div key={item._id} className="row">
                        <div className='cart-item-img'><img src={item.product.img} alt="" /></div>
                        <div className='cart-item-name'>{item.product.name}
                            <div>€{item.product.price.toFixed(2)}</div>
                            <div className='quantity-price-buttons'><button onClick={() => onRemove(item)}>-</button>
                                {item.quantity}

                                <button onClick={() => onAdd(item)}>+</button></div></div>




                        <div className='cart-price'>€{(item.product.price * item.quantity).toFixed(2)}</div>
                    </div>

                ))}
                <div className='clear-cart'><button onClick={handleClear}>Remove All Items From Cart</button></div>

            </div>
            <div className='totals'>

                <h2>Cart Subtotal : €{cartTotal}</h2>
                <h2>Shipping: €{shippingCost}</h2>
                <h1>Total: €{totalToPay}</h1>
            </div>
            <div className='checkout'><button onClick={() => createCheckoutSession()}>Check Out!</button></div>
        </div>

    )
}
