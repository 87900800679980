import React from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/navbar.css'



export default function Navbar(props) {

  const {countCartItems, isLoggedIn, logout} = props;

  console.log(isLoggedIn);
    

  return (
    <div className='navbar'>
    <NavLink to="/" style={({isActive})=> isActive ? styles.activeLink : styles.defaultLink} >
        Home</NavLink>

    <NavLink to="/products" style={({isActive})=> isActive ? styles.activeLink : styles.defaultLink} >Shop All Products</NavLink>

    <NavLink to="/categories" style={({isActive})=> isActive ? styles.activeLink : styles.defaultLink} >Shop Category</NavLink>

    <NavLink to="/contact" style={({isActive})=> isActive ? styles.activeLink : styles.defaultLink} >Contact</NavLink>

   {/* {!isLoggedIn && <NavLink to="/account" style={({isActive})=> isActive ? styles.activeLink : styles.defaultLink} >Account</NavLink>} */}

  <div className='account-cart'>{isLoggedIn ? <button onClick={() => logout()}>Log Out</button> :  <NavLink to="/account" style={({isActive})=> isActive ? styles.activeLink : styles.defaultLink}><i className="fa-solid fa-user-large"></i></NavLink>}

  <NavLink to="/cart" >
   <i className="fa-solid fa-basket-shopping"></i> ({countCartItems})
    </NavLink>
    </div>
   
    
    </div>
  )
}

const styles = {
    activeLink: {
        color: "rgba(85, 81, 102, 1)",
        textDecoration: "none",
        border: "5px solid rgba(85, 81, 102, 1)",
        padding: "0.5em",  
        fontSize: '1.2em',
        margin: '1em',
        borderRadius: '10px'

    },
    defaultLink: {
        color: "black",
        textDecoration: "none",
        fontWeight: "bold",

    }}




