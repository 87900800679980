import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { URL } from '../config'
import { useNavigate } from 'react-router-dom'
import * as jose from 'jose'
import Helmet from 'react-helmet'




export default function Login(props) {

  const [message, setMessage] = useState("");


  const [form, setForm] = useState({
    email: "",
    password: "",

  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${URL}/members/login`, {
        email: form.email.toLowerCase(),
        password: form.password,
      });
      setMessage(response.data.message);
      if (response.data.ok) {
        // here after login was successful we extract the email passed from the server inside the token 
        let decodedToken = jose.decodeJwt(response.data.token)
        // and now we now which user is logged in in the client so we can manipulate it as we want, like fetching data for it or we can pass the user role -- admin or not -- and act accordingly, etc...
        console.log("Email extracted from the JWT token after login: ", decodedToken.userEmail)
        setTimeout(() => {
          props.login(response.data.token);
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className='form-wrapper'>

      <Helmet>
        <title>Login</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of login page with React.js and JWT authentication" />
        <meta name="keywords" content="accounts, authetication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>


      <form onChange={handleChange} onSubmit={handleSubmit} className="form-container">
        <label>Email</label>
        <input type="email" name="email" />

        <label>Password</label>
        <input type="new-password" name='password' />

        <button>Login</button>
        <h4 className='message'>{message}</h4>

      </form></div>
  )
}
