import React from 'react';
import '../styles/footer.css'

export default function Footer() {

    return (
    <footer>

    <div className='footerheader'>Follow Plateo!</div>
    <div className="socials">
        <i className="fa-brands fa-facebook"></i>
        <i className="fa-brands fa-twitter"></i>
        <i className="fa-brands fa-reddit-alien"></i>
        <i className="fa-brands fa-tiktok"></i>






    </div>


    </footer>
    )

}