import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { URL } from '../config'
import { useNavigate } from 'react-router-dom'
import * as jose from 'jose'
import '../styles/account.css'
import Helmet from 'react-helmet'

export default function Account(props) {

  ///// REGISTER ///////

  const [message, setMessage] = useState("")

  const [form, setForm] = useState({
    email: "",
    password: "",
    password2: "",

  });

  const [loginMessage, setLoginMessage] = useState("");


  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",

  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${URL}/members/register`, {
        email: form.email,
        password: form.password,
        password2: form.password2
      });

      setMessage(res.data.message)
      if (res.data.ok) {
        setTimeout(() => {
          props.login()

        }, 1500)
      }
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(() => {
    setTimeout(() => {
      setMessage("")
    }, 1500)
  }, [message])


  /////////LOGIN ////////////////

  const [loginRender, setLoginRender] = useState('')


  const navigate = useNavigate();

  const handleLoginChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value })
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${URL}/members/login`, {
        email: loginForm.email.toLowerCase(),
        password: loginForm.password,
      });
      setLoginMessage(response.data.message);
      if (response.data.ok) {
        // here after login was successful we extract the email passed from the server inside the token 
        let decodedToken = jose.decodeJwt(response.data.token)
        // and now we now which user is logged in in the client so we can manipulate it as we want, like fetching data for it or we can pass the user role -- admin or not -- and act accordingly, etc...
        console.log("Email extracted from the JWT token after login: ", decodedToken.userEmail)
        setTimeout(() => {
          props.login(response.data.token);
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoginMessage("")
    }, 1500)
  }, [loginMessage])




  return (
    <div>
      <Helmet>
        <title>Accounts</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of accounts page with React.js and JWT authentication" />
        <meta name="keywords" content="accounts, authetication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <div className='intros'>
        <div>
          <p className='introtext'>Not a member yet?</p>
          <p className='introtext'>Do the right thing!</p>
        </div>
        <div>
          <p className='introtext'>Part of the club?</p>
          <p className='introtext'>Sign in and shop!</p>

        </div>

      </div>

      <div className="account">
        <div className="form-wrapper">

          <form onChange={handleChange} onSubmit={handleSubmit} className="form-container">
            <h3>Register</h3>
            <label>Email</label>
            <input className="form-inputs" type="email" name="email" />

            <label>Password</label>
            <input className="form-inputs" type="password" name='password' />

            <label>Re-enter Password</label>
            <input className="form-inputs" type="password" name='password2' />
            <button>Register</button>
            <h4 className='message'>{message}</h4>
          </form>
        </div>


        <div className='form-wrapper'>

          <form onChange={handleLoginChange} onSubmit={handleLoginSubmit} className="form-container">
            <h3>Login</h3>
            <label>Email</label>
            <input type="email" name="email" />

            <label>Password</label>
            <input type="new-password" name='password' />

            <button>Login</button>
            <h4 className='message'>{loginMessage}</h4></form>

        </div>
      </div>
    </div>



  )
}
