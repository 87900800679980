import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Helmet from 'react-helmet'
import {URL} from '../config'

export default function Categories() {

  const [categories, setCategories] = useState([]);
  const [current, setCurrent] = useState(0);
  const length = categories.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  let getCategories = () => {
    axios.get(`${URL}/categories/`)
      .then((res) => {
        setCategories(res.data)


      })
      .catch((err) => {
        console.log(err)

      })
  }


  useEffect(() => {
    getCategories()
  }, [])

  let renderCategoriesSlider = () => (
    categories.map((cat, idx) =>

      <div key={cat._id} className={idx == current ? 'slide-active' : 'carousel-img'}>

        <img src={cat.imgurl} alt="" className='carousel-img' />
        <h4>

          <Link
            className={idx == current ? 'cat-active' : 'cat-inactive'}
            to={`/categories/${cat._id}`}>
            {cat.category}
          </Link>

        </h4>
      </div>
    ))

  let renderCategoriesGrid = () => (
    categories.map((cat, idx) =>

      <div key={cat._id}>

        <img src={cat.imgurl} alt="" />
        <h4>

          <Link

            to={`/categories/${cat._id}`}>
            {cat.category}
          </Link>

        </h4>
      </div>
    ))




  return (
    <div>

      <Helmet>
        <title>Categories</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of accounts page with React.js" />
        <meta name="keywords" content="categories, e-commerce, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <section className='slider-section'>
        <div className='slider'>

          {renderCategoriesSlider()}
          <div className="sliderbtns">
            <button onClick={prevSlide} className='carousel-previous'>&#8701;</button>
            <button onClick={nextSlide} className='carousel-next'>&#8702;</button>
          </div>
        </div>


      </section>

      <section className='cat-main'>
        <p className='categories-here-header'>Shop your favourite categories here!</p>
        {renderCategoriesGrid()}

      </section>

    </div>


  )
}
