import './styles/App.css';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import '@stripe/stripe-js';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Header from './Components/header';
import Home from './Views/home';
import Products from './Views/products';
import SingleProduct from './Views/single-product';
import Categories from './Views/categories';
import SingleCategory from './Views/single-category';
import Contact from './Components/contact';
import Account from './Views/account';
import Login from './Views/login';
import BurgerNavbar from './Components/burger-menu';
import Navbar from './Components/navbar';
import Cart from './Views/cart';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentSuccess from './Views/payment-success';
import PaymentError from './Views/payment-error';
import Footer from './Components/footer';
import axios from 'axios';
import { URL } from './config.js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
console.log('KEY: ', process.env.REACT_APP_STRIPE_PUBLIC_KEY)
function App() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [cartItems, setCartItems] = useState([])


  const onAdd = (product) => {
    const exists = cartItems.find(ele => ele._id === product._id)

    if (exists) {
      setCartItems(cartItems.map(x => x._id === product._id ? { ...exists, in_cart: true, quantity: exists.quantity + 1, amount: ((exists.quantity + 1) * exists.product.price) } : x))
    }
    else {
      setCartItems([...cartItems, { ...product, in_cart: true, quantity: 1 }])
    }

  }

  const onRemove = (product) => {
    const exists = cartItems.find(ele => ele._id === product._id)
    if (exists.quantity === 1) {
      setCartItems(cartItems.filter(x => x._id !== product._id))
    }
    else {
      setCartItems(cartItems.map(x => x._id === product._id ? { ...exists, quantity: exists.quantity - 1 } : x))
    }
  }

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const [token, setToken] = useState(null);

  useEffect(
    () => {
      const verify_token = async () => {
        try {
          if (!token) {
            setToken(JSON.parse(localStorage.getItem('token')))
            setIsLoggedIn(false)
          }
          axios.defaults.headers.common['Authorization'] = token;
          const response = await axios.post(`${URL}/members/verify_token`);
          return response.data.ok ? login(token) : logout();
        } catch (error) {
          console.log(error);
        }
      };
      verify_token();
    },
    [token]
  );


  const login = (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    setIsLoggedIn(true);
  };
  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };


  return (
    <div className='App'>

      <Router>
        <BurgerNavbar countCartItems={cartItems.length} />
        <Header />
        <Navbar countCartItems={cartItems.length} isLoggedIn={isLoggedIn} logout={logout} />
        <Routes>
          <Route path="/" element={<Home isLoggedIn={isLoggedIn} />}></Route>
          <Route path="/products" element={<Products onAdd={onAdd} cartItems={cartItems} />}></Route>
          <Route path="/products/:product" element={<SingleProduct onAdd={onAdd} />}></Route>

          <Route path="/categories" element={<Categories />}></Route>
          <Route path="/categories/:category" element={<SingleCategory onAdd={onAdd} />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/account" element={isLoggedIn ? <Navigate to="/" /> : <Account login={login} />}></Route>
          <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login login={login} />}></Route>


          <Route path="/cart" element={<Elements stripe={stripePromise}><Cart setCartItems={setCartItems} onRemove={onRemove} onAdd={onAdd} cartItems={cartItems} /></Elements>}></Route>


          <Route path="/payment/success" element={<PaymentSuccess />}></Route>
          <Route path="/payment/error" element={<PaymentError />}></Route>


        </Routes>
      </Router>
      <Footer />
    </div>



  );
}

export default App;
