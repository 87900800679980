import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Helmet from 'react-helmet'
import {URL} from '../config'


export default function SingleProduct(props) {
  const { onAdd } = props;
  const params = useParams();
  const [product, setProduct] = useState([])
  console.log('params are', params)



  useEffect(() => {
    const getProds = async () => {
      axios.get(`${URL}/products/`)
        .then((res) => {
          setProduct(res.data)
          console.log(product)
        })
        .catch((err) => {
          console.log(err)

        })
    }
    getProds()
  }, [])

  let renderProducts = () => (
    product.map((prod, idx) => {
      if (prod._id == params.product)
        return <div className="singleProduct" key={prod._id}>
          <div className='single-product-img'>
            <img src={prod.product.img} alt="" />
          </div>
          <div className="single-product-info">
            <h3>{prod.product.name}</h3>

            <div><p>{prod.product.description}</p></div>
            <div>€{prod.product.price}<button onClick={() => onAdd(prod)}>Add to Cart</button>
            </div>
          </div>
        </div>
    }

    ))




  return (
    <div>

      <Helmet>
        <title>Product</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Example implementation of product page with React.js and JWT authentication" />
        <meta name="keywords" content="accounts, authetication, reactjs" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <div style={{ textAlign: 'left', padding: '1em', border: '1px solid black', width: 'fit-content' }}><p><Link to="/products">Go back?</Link></p></div>
      {renderProducts()}
    </div>

  )
}
