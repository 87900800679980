import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/header.css'

export default function Header() {
  return (
    <div className='header-background'>
        <Link to="/">
        <h3 className='header-words-main'>Plateo.</h3>

            
        </Link>
       


        </div>
  )
}
